import styled, { css, CSSProp } from 'styled-components'

import Link from './link'

export const Category = styled(Link)`
  ${({ theme }): CSSProp => css`
    font-size: ${theme.font.fontSizeSmall};
    font-weight: bold;
    text-transform: uppercase;
    color: ${theme.colours.tertiary};
    letter-spacing: 1px;
    text-decoration: none;
  `}
`
