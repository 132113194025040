import styled, { css, CSSProp } from 'styled-components'

type BodyCopyProps = {
  size?: 'large'
}

const BodyCopy = styled.div<BodyCopyProps>`
  ${({ theme }): CSSProp => css`
    font-size: ${theme.font.fontSizeBodyMobile};
    font-weight: ${theme.font.fontWeightLight};

    @media only screen and ${theme.breakpoints.fromSmallScreen} {
      font-size: ${theme.font.fontSizeBodyDefault};
    }

    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      font-size: ${(props: BodyCopyProps): string =>
        props.size === 'large' ? theme.font.fontSizeBodyLarge : 'inherit'};
      line-height: 1.45;
    }
  `}
`

export default BodyCopy
