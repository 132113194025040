import Container from 'components/container'
import NavigationLink from 'components/molecules/navigation-link'
import Img, { FluidObject } from 'gatsby-image'
import { isJapanese } from 'helpers/locale'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'
import { Subheadings,WholesaleH1 } from 'styles/base'

const StyledHero = styled.div<{ align: 'center' }>`
  ${({ theme, align }): CSSProp => css`
    ${isJapanese() &&
        css`
        h1 {
          font-family: ${ theme.font.fontFamilyDefault };
        }`
    }
    position: relative;
    background-color: ${theme.colours.primary};
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      background: ${theme.colours.primary};
      padding-top: ${theme.layout.headerHeightDesktop};
    }
    h3 {
      font-size: 1.2rem;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      margin-bottom: 3rem;
      color: ${theme.colours.tertiary};
    }
    .content {
      position: relative;
      color: ${theme.colours.secondary};
      padding: 4rem 0;
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        position: absolute;
        left: 2rem;
        right: 2rem;
        bottom: 0;
      }
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        padding: 0 0 0 8rem;
        min-height: 35rem;
        display: flex;
        align-items: center;
        max-width: 55rem;
        ${align === 'center' &&
          css`
            padding: 0 3rem;
            text-align: center;
            margin: 0 auto;
          `}
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        min-height: 50rem;
        max-width: 90rem;
        padding: 0;
      }

      > div {
        position: relative;
        z-index: 1;
      }

      &-inner {
        width: 100%;
      }
    }
    .cta {
      margin-top: 2rem;
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        margin-top: 3rem;
      }
      a {
        margin: 0 1.5rem 1rem 0;
      }
    }
    .gatsby-image-wrapper {
      height: 100%;
      min-height: 45rem;
      max-height: 45rem;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        min-height: 40rem;
        max-height: none;
      }
    }

    .image {
      opacity: 0.72;
    }
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      .image {
        opacity: 0.9;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 55%;
        ${align === 'center' &&
          css`
            opacity: 0.5;
            width: 100%;
          `}

        :after {
          content: '';
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          width: 35rem;
          max-width: 70%;
          height: 100%;
          background: linear-gradient(
            to right,
            ${theme.colours.primary},
            rgba(0, 0, 0, 0)
          );
          ${align === 'center' &&
            css`
              display: none;
            `}
        }
      }
      img {
        min-height: 0;
      }
    }
    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      .image {
        ${align === 'center' &&
          css`
            :before {
              content: '';
              position: absolute;
              z-index: 1;
              top: 0;
              right: 0;
              width: 30rem;
              max-width: 70%;
              height: 100%;
              background: linear-gradient(
                to left,
                ${theme.colours.primary},
                rgba(0, 0, 0, 0)
              );
            }
            :after {
              ${align === 'center' &&
                css`
                  width: 30rem;
                  display: block;
                `}
            }
          `}
      }
    }
  `}
`
type HeroProps = {
  title: string
  subTitle?: string
  image: FluidObject | FluidObject[]
  body?: any
  cta?: any[]
  align?: 'center'
}

const WholesaleHero = ({
  title,
  subTitle,
  image,
  body,
  cta,
  align,
}: HeroProps): JSX.Element =>
  image ? (
    <StyledHero align={align}>
      <div className="image">
        <Img alt={title} fluid={image} />
      </div>

      <Container>
        <div className="content">
          <div className="content-inner">
            {subTitle && <Subheadings>{subTitle}</Subheadings>}
            <WholesaleH1>{title}</WholesaleH1>
            {body && <div>{body?.body}</div>}
            <div className="cta">
              {cta?.map(item => (
                <NavigationLink menuItem={item} key={item.id} type="button" />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </StyledHero>
  ) : null

export default WholesaleHero
