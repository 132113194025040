import classnames from 'classnames'
import Img, { FluidObject } from 'gatsby-image'
import { isJapanese } from 'helpers/locale'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'
import { WholesaleBlockQuote } from 'styles/base'

import {
  ContentfulLinkContentfulRegionalLinkUnion,
  Maybe,
} from '../../types/graphql-types'
import Container from '../container'
import NavigationLink from '../molecules/navigation-link'

const StyledCTABlock = styled(Container)`
  ${({ theme }): CSSProp => css`
    margin-top: ${theme.padding.screenDefault};
    margin-bottom: ${theme.padding.screenDefault};
    display: flex;
    flex-direction: column;

    @media only screen and ${theme.breakpoints.fromSmallScreen} {
      flex-direction: row;
    }
  `}
`

const AlternateWrapper = styled.div`
  &.no-margin-top {
    ${StyledCTABlock} {
      margin-top: 0;
    }
  }

  &.no-margin-bottom {
    ${StyledCTABlock} {
      margin-bottom: 0;
    }
  }

  &.alternate {
    background-color: #e3d5c4;
    position: relative;

    &::before {
      content: '';
      background-image: url('/watermark.png');
      height: 100%;
      width: 50%;
      background-size: cover;
      position: absolute;
      background-position-x: right;
    }
  }
`

const BodyWrapper = styled.div`
  ${({ theme }): CSSProp => css`
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 ${theme.padding.mobileDefault};

    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      padding: ${theme.padding.screenDefault};

      &.alternate {
        padding: ${theme.padding.largeScreenDefault}
          ${theme.padding.screenDefault};
      }
    }

    &.alternate {
      padding: ${theme.padding.largeScreenDefault}
        ${theme.padding.mobileDefault};
    }

    .body {
      font-size: 1.3rem;
      font-weight: ${theme.font.fontWeightLight};
      @media only screen and ${theme.breakpoints.fromSmallScreen} {
        font-size: ${theme.font.fontSizeBodyDefault};
      }
    }

    &:not(.has-images) {
      .body {
        ${isJapanese() ?
          css`
            font-family: ${ theme.font.fontFamilyDefault };
            font-weight: ${ theme.font.fontWeightBold };
          `
          : css`font-family: ${ theme.font.fontFamilyAlt };`
        };
        font-size: ${theme.font.fontSizeLargeMobile};
        font-weight: bold;
        line-height: 1.5;
        max-width: 960px;
        margin: 0 auto;

        @media only screen and ${theme.breakpoints.fromSmallScreen} {
          font-size: ${theme.font.fontSizeLargeTablet};
        }

        @media only screen and ${theme.breakpoints.fromLargeScreen} {
          font-size: ${theme.font.fontSizeLargeDesktop};
        }
      }
    }

    &.has-images {
      text-align: left;
      padding-top: ${theme.padding.mobileDefault};

      @media only screen and ${theme.breakpoints.fromSmallScreen} {
        width: 50%;
        padding: 0 ${theme.padding.mobileDefault};
      }

      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        padding: 0 ${theme.padding.screenDefault};
      }

      .cta {
        @media only screen and ${theme.breakpoints.toMediumScreen} {
          display: flex;
          flex-direction: column;

          a {
            margin: 10px 0 0 0;
            text-align: center;
          }
        }
      }
    }

    .cta {
      margin-top: 20px;
     

      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        margin-top: 0rem;
      }

      a,
      button {
        margin: 0 0px;
        margin-bottom: 20px;
        width : 18rem;
        @media only screen and ${theme.breakpoints.fromNormalScreen} {
          margin-bottom: 00px;
          width : auto;

        }
        @media only screen and ${theme.breakpoints.fromMediumScreen} {
          margin: 0 1rem 1rem 0;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .blockQuoteContainer {
      padding-left: 0rem;
      padding-right: 0rem;
      
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        padding-left: 20rem;
        padding-right: 20rem;
      }
    }
  `}
`

const ImagesWrapper = styled.div`
  flex-basis: 50%;

  &.three-images {
    position: relative;

    .image-1 {
      width: 60.8%;
    }

    .image-2 {
      width: 31.2%;
      position: relative;
      margin-left: 29.6%;
      margin-top: 2.5%;
    }

    .image-3 {
      width: 36.7%;
      position: absolute;
      right: 0;
      top: 22.5%;
    }
  }
`

const WholesaleCtaBlock = ({
  body,
  cta,
  images,
  alternate,
  noMarginPadding,
}: {
  body: string
  cta?: Maybe<Array<Maybe<ContentfulLinkContentfulRegionalLinkUnion>>>
  images?: [
    {
      media: FluidObject
    }
  ]
  alternate?: boolean
  noMarginPadding?: boolean
}): JSX.Element => {
  const numberOfImages = images ? images.length.toString() : '0'

  const renderSingleImage = (): JSX.Element => {
    return <p>1 Image</p>
  }

  const renderTwoImages = (): JSX.Element => {
    return <p>2 Images</p>
  }

  const renderThreeImages = (): JSX.Element => {
    const aspectRatio = [1.515151515, 0.711111111, 0.697674419]

    return (
      <ImagesWrapper className="three-images">
        {images.map((image, key) => {
          return (
            <Img
              className={`image-${key + 1}`}
              fluid={[
                { ...image.media[0].fluid, aspectRatio: aspectRatio[key] },
              ]}
              alt={image.media[0].title}
              key={key}
              style={key === 2 ? { position: 'absolute' } : null}
            />
          )
        })}
      </ImagesWrapper>
    )
  }

  const renderImages = (): JSX.Element => {
    switch (true) {
      case numberOfImages === '1':
        return renderSingleImage()
      case numberOfImages === '2':
        return renderTwoImages()
      case numberOfImages === '3':
        return renderThreeImages()
    }

    return null
  }

  const bodyClass = classnames({
    alternate,
    'has-images': numberOfImages === '3',
  })

  const wrapperClass = classnames({
    alternate,
    'no-margin-bottom': noMarginPadding,
    'no-margin-top': noMarginPadding,
  })

  const renderBody = (): JSX.Element => {
    return (
      <BodyWrapper className={bodyClass}>
        <div className='blockQuoteContainer'>
          <WholesaleBlockQuote>
            {body}
          </WholesaleBlockQuote>
        </div>
        {cta?.length > 0 && (
          <div className="cta">
            {cta.map(item => (
              <NavigationLink
                alternate={alternate}
                menuItem={item}
                key={item.id}
                type="button"
              />
            ))}
          </div>
        )}
      </BodyWrapper>
    )
  }

  return (
    <AlternateWrapper className={wrapperClass}>
      <StyledCTABlock>
        {renderImages()}
        {renderBody()}
      </StyledCTABlock>
    </AlternateWrapper>
  )
}

export default WholesaleCtaBlock
